



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import BackBar from '@/components/BackBar.vue';
import { ExtendedRunUnit } from '@/store/interface/Unit';

const JwlUnit = () => import('@/components/JwlUnit.vue');
const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    BackBar,
    CommonError,
    CommonIcon,
    JwlUnit,
  },
})
export default class JwlUnitOverview extends Vue {
  loading = true;
  error = null;
  unit: ExtendedRunUnit | null = null;

  @Watch('$route')
  fetchData (): void {
    this.loading = true;
    this.unit = null;
    this.error = null;
    this.$store.dispatch('getData', `unit/${this.$route.params.unit}`)
      .then((data) => {
        this.unit = data;
        this.loading = false;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  get rootClasses (): Record<string, boolean> {
    return {
      'jwl-unit-overview--bachelor': this.unit != null && this.unit.programmeType === 'bachelor',
      'jwl-unit-overview--professional': this.unit != null && this.unit.programmeType === 'professional',
      'jwl-unit-overview--language': this.unit != null && this.unit.programmeType === 'language',
      'jwl-unit-overview--teacher-training': this.unit != null && this.unit.programmeType === 'teacher-training',
      'jwl-unit-overview--academy': this.unit != null && this.unit.programmeType === 'academy',
    };
  }

  created (): void {
    this.fetchData();
  }
}

